
.x_title span.btn {
    color: #fff;
}

.col-md-3.left_col {
    z-index: 100 !important;
}

.row.beetje-omlaag {
    margin-top: 40px;
}

.stepContainer {
    height: 400px !important;
}
.buttonFinish  {
    display: none;
}

/* Frontend */
body {
}

.bg-gray {
    background: #f9f9f9;
    padding: 10px 15px;
    border-bottom: 1px solid #FFF;
}

h3 {
    margin-bottom: 30px;
}

.main {
    padding: 50px 0;
}

.bg-success {
    background-color: #5cb85c!important;
    color: #FFF !important;
}

.bottom-20 {
    padding-bottom: 10px;
}

.bottom-30 {
    margin-bottom: 25px;
}

.bottom-40 {
    margin-bottom: 40px;
}

label a:hover {
    text-decoration: none;
}

/* NAV */

.nav li {
    margin-bottom: 10px;
}

.sidebar .list-group-item .glyphicon {
    float: right;
}

.form-group {
    width: 100%;
    float: left;
}

/* SIDEBAR */

.sidebar {
    padding-top: 15px;
}


select, input {
    display: block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
/* CONTENT */
/*# sourceMappingURL=frontend.min.css.map */

/*

Purple #4c3794


 */


/* CUSTOM STYLES */

* {
    border-radius: 0 !important;
}

body {
    background: #f5f1f6;
    color: #54575a;
    font-size: 16px;
    line-height: 25px;
}

h1, h2, h3,
.list-group-item{
    font-family: 'Bitter', serif;
}

a,
.list-group-item.active > .badge, .nav-pills > .active > a > .badge{
    color: #4c3794;
}

a:hover, a:focus {
    color: #ef337d;
    text-decoration: underline;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus{
    background: #4c3794;
    border-color: #39296f;
}

.badge {
    border-radius: 10px !important;
    padding: 4px 7px;
}

.btn {
    padding: 10px 20px;
}

.btn-default {
    background: #4c3794;
    text-transform: uppercase;
    text-shadow: none;
    color: #FFF;
    border: 0;
}

.btn-default:hover {
    background: #39296f;
    color: #FFF;
}

.content p {
    margin-bottom: 40px;
}

.content h2 {
    display: inline-block;
}

input,
select{
    border: 0;
    padding: 10px 20px;
    height: 47px;
    box-shadow: none;
    font-size: 16px;
}



input:focus,
select:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
    outline: none;
}

select option {
    line-height: 30px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #d1cde1;
    font-style: italic;
    font-weight: 600;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #d1cde1;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #d1cde1;
}
:-moz-placeholder { /* Firefox 18- */
    color: #d1cde1;
}

.tooltip.top .tooltip-inner {
    background-color:#ef337d;
}
.tooltip.top .tooltip-arrow {
    border-top-color: #ef337d;
}

.progress-bar {
    padding: 0px 10px;
    background: #4c3794;
}

.bg-success {
    background-color: #ef337d!important;
    color: #FFF !important;
    border-color: #ef337d ;
}

label {
    padding-top: 3px;
    line-height: 22px;
}

@media screen and (max-width: 767px) {

    .main.container {
        padding: 0 15px;
    }

}

.content p a {
    text-decoration: underline;
}

h1 {
    color: #4c3794;
}

.logo {
    padding-bottom: 20px;
    margin-top: -35px;
}

@media screen and (max-width: 767px) {
    .logo {
        padding: 0px 0 20px;
        margin-top: 10px;
    }

    .welcome .logo {
        padding: 15px 15px 15px 0 !important;
    }

    input,
    select{
        margin-left: 15px;
    }

    .home-form select {
        margin-left: 0;
    }

}

@media screen and (max-width: 574px) {

    .logo img {
        width: 100%;
        padding-right: 10px;
    }

}

@media screen and (max-width: 400px) {

    h1 {
        font-size: 22px;
    }

}

.welcome .logo {
    padding: 15px 0 15px 15px;
    margin-top: 0;
}

.welcome p {
    padding: 25px 0;
}


.col-xs-12.bottom-20.bg-gray.grand-total {
    margin-top: 40px;
    margin-bottom: 40px;
}

.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
    margin-top: -12px;
}